@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: BernaseRoman;
  src: url("/public/media/font/BernaseRoman.otf") format("opentype");
}

@font-face {
  font-family: AppareoMedium;
  src: url("/public/media/font/AppareoMedium.otf") format("opentype");
}

@font-face {
  font-family: PortuguesaCaps;
  src: url("/public/media/font/PortuguesaCaps.otf") format("opentype");
}

body {
  margin: 0;
  font-family: BernaseRoman, sans-serif;
}

#appareo{
  font-family: AppareoMedium, sans-serif;
}

.font-portuguesa-caps{
  font-family: PortuguesaCaps, sans-serif;
}
